import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import {
  Box,
  Button,
  Link,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import CardForm, { CardFormValues } from 'src/modules/Forms/CardForm';
import PixForm from 'src/modules/Forms/PixForm';
import { SuccessModal } from 'src/components/Modal/ModalVariants/SuccessModal';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { BookingActions } from 'src/redux/booking';
import {
  BookingStatusEnum,
  getBookingByID,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import { useForm } from 'react-hook-form';
import * as fbq from '../../../utils/pixels/fpixel';
import usePHToast from 'src/hooks/useToast';
import { onlineCourtesy } from 'src/api/services/charge';
import { LocalPaymentRequest } from 'src/api/interfaces/charge';

export interface PixData {
  qrCodeImageUrl: string;
  qrCodeData: string;
  pixGenerationTime?: string;
}

export interface PaymentProps {
  isRebuy?: boolean;
}

export default function Payment({ isRebuy = false }: PaymentProps) {
  const { bookingID } = useParams<{ bookingID: string }>();
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = usePHToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const cardForm = useForm<CardFormValues>();

  const [paymentMethod, setPaymentMethod] = useState<
    'credit' | 'debit' | 'pix' | 'courtesy'
  >('credit');
  const [qrCode, setQrCode] = useState<PixData | undefined>(undefined);
  const [idempotencyKey, setIdempotencyKey] = useState<string | undefined>(
    undefined
  );

  const { data: booking } = useGetBookingByID(bookingID);

  const [loadingGenerateCourtesy, setLoadingGenerateCourtesy] =
    useState<boolean>(false);

  const triggerPurchaseEvent = () => {
    if (isRebuy || !booking || process.env.react_app_env === 'dev') return;

    fbq.purchaseEvent(booking.total, 'BRL');
    window.gtag('event', 'purchase', {
      value: booking.total,
      currency: 'BRL',
    });
    TikTokPixel.track('CompletePayment', {
      value: booking.total,
      currency: 'BRL',
    });
  };

  const handlePixGenerated = (data?: PixData) => {
    setQrCode(data);
  };

  const handleIdempotencyKey = (key?: string) => {
    setIdempotencyKey(key);
  };

  const handleSuccess = () => {
    dispatch(BookingActions.resetBooking());
    onClose();
    navigate('/');
  };

  // const handleStatusChange = useCallback(() => {
  //   if (booking?.bookingStatusID === BookingStatusEnum.CONFIRMADA) {
  //     if (['pix'].includes(paymentMethod)) triggerPurchaseEvent();
  //     onOpen();
  //     dispatch(BookingActions.resetBooking());
  //   }
  // }, []);

  // useEffect(() => {
  //   handleStatusChange();
  // }, [booking?.bookingStatusID]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
    fbq.event('AddPaymentInfo');
  }, []);

  const handlePixUpdate = () => {
    if (!bookingID) return;
    getBookingByID(bookingID)
      .then((res) => {
        if (res?.bookingStatusID === BookingStatusEnum.CONFIRMADA) {
          if (['pix'].includes(paymentMethod)) triggerPurchaseEvent();
          onOpen();
          dispatch(BookingActions.resetBooking());
          navigate('/jogador/historico');
          toast({ status: 'success', title: 'Reserva concluída com sucesso!' });
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    if (qrCode?.qrCodeData) {
      const interval = setInterval(() => {
        handlePixUpdate();
      }, 1000 * 10);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [qrCode]);

  useEffect(() => {
    if (booking && !(booking.total > 0)) {
      setPaymentMethod('courtesy');
    }
  }, [booking]);

  const handleGenerateCourtesy = async () => {
    if (!user)
      return toast({
        status: 'error',
        title: 'É necessário estar logado para comprar.',
      });
    if (!booking)
      return toast({
        status: 'error',
        title: 'É necessário ter uma reserva comprar.',
      });
    setLoadingGenerateCourtesy(true);

    const data: LocalPaymentRequest = {
      userId: user.userID,
      bookingId: booking.bookingID,
      paymentMethodID: 9,
      installments: 0,
    };

    onlineCourtesy(data)
      .then((response) => {
        if (response.result.sucess) {
          handleIdempotencyKey(response.result.idempotencyKey);
          triggerPurchaseEvent();
          onOpen();
        }
      })
      .catch((err) => {
        console.log({ err });

        toast({
          status: 'error',
          title: 'Não foi possível gerar a reserva como cortesia!',
        });
      })
      .finally(() => setLoadingGenerateCourtesy(false));
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={2} px={4}>
        <Text variant="title">FORMAS DE PAGAMENTO</Text>
        <DashedContainer justify="center">
          {!qrCode && (
            <Stack
              direction={['column', 'row']}
              w="full"
              spacing={2}
              align="flex-start"
            >
              {Number(booking?.total) > 0 ? (
                <>
                  <Button
                    variant="pophausOutline"
                    bg={paymentMethod === 'credit' ? 'white' : undefined}
                    w="full"
                    onClick={() => setPaymentMethod('credit')}
                  >
                    cartão de crédito
                  </Button>
                  {/* <Button
                variant="pophausOutline"
                bg={paymentMethod === 'debit' ? 'white' : undefined}
                w="full"
                onClick={() => setPaymentMethod('debit')}
              >
                cartão de débito
              </Button> */}
                  <Button
                    variant="pophausOutline"
                    bg={paymentMethod === 'pix' ? 'white' : undefined}
                    w="full"
                    onClick={() => setPaymentMethod('pix')}
                  >
                    pix
                  </Button>
                </>
              ) : (
                <Button
                  variant="pophausOutline"
                  bg={paymentMethod === 'courtesy' ? 'white' : undefined}
                  w="full"
                  onClick={() => setPaymentMethod('courtesy')}
                >
                  cortesia
                </Button>
              )}
            </Stack>
          )}
          {Number(booking?.total) > 0 ? (
            <>
              {['credit', 'debit'].includes(paymentMethod) && (
                <CardForm
                  cardForm={cardForm}
                  paymentMethod={paymentMethod}
                  idempotencyKey={idempotencyKey}
                  bookingID={bookingID}
                  onOpen={onOpen}
                  onErrorOpen={onErrorOpen}
                  handleIdempotencyKey={handleIdempotencyKey}
                  triggerPurchaseEvent={triggerPurchaseEvent}
                />
              )}
              {['pix'].includes(paymentMethod) && (
                <PixForm
                  qrCode={qrCode}
                  idempotencyKey={idempotencyKey}
                  bookingID={bookingID}
                  handleIdempotencyKey={handleIdempotencyKey}
                  handlePixGenerated={handlePixGenerated}
                  onErrorOpen={onErrorOpen}
                />
              )}
            </>
          ) : (
            <Button
              variant={'pophausOutline'}
              colorScheme="popGreen"
              mt={'1rem'}
              onClick={handleGenerateCourtesy}
              isLoading={loadingGenerateCourtesy}
            >
              Confirmar Reserva
            </Button>
          )}
        </DashedContainer>
      </VStack>

      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        handleMainClick={handleSuccess}
        content={
          !(Number(booking?.total) > 0)
            ? 'RESERVA CONFIRMADA COM SUCESSO!'
            : undefined
        }
      />
      <ErrorModal
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        handleMainClick={onErrorClose}
      />
    </PageContainer>
  );
}
